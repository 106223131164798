<template>
  <div class="flex flex-col w-full">
  <div style="height:100%" class="mt-28" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <card class="w-full flex flex-col mt-2 p-5" v-else>

      <div class="w-full gap-x-12 gap-y-5 mb-6 flex flex-col"
      v-if="amountData === 0">
      <div class="my-4 w-full flex py-6 justify-center">
        <img src="@/assets/images/Savings.png" alt="" />
      </div>
      </div>

      <div class="w-full gap-x-12 gap-y-5 mb-6 flex flex-wrap" v-else>
        <Card
          class="w-1/3 text-white py-6 px-4"
        >
          <div class="flex justify-between">
            <card
              class="p-2"
              style="height: 40px;"
            >
              <Icon
                class-name="text-darkPurple -mb-2"
                icon-name="expense-union"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">Principal Amount</span>
              <span class="text-lg font-extrabold text-darkPurple mt-5">{{convertToCurrency(principalAmount)}}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card
          class="w-1/3 py-6 px-4"
        >
          <div class="flex justify-between">
            <card
              class="p-2"
              style="height: 40px;"
            >
              <Icon
                class-name="text-darkPurple -mb-2"
                icon-name="expense-union"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">Interest Amount</span>
              <span class="text-lg font-extrabold text-darkPurple mt-5">{{convertToCurrency(interestAmount)}}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card
          class="w-1/3 py-6 px-4"
        >
          <div class="flex justify-between">
            <card
              class="p-2"
              style="height: 40px;"
            >
              <Icon
                class-name="text-darkPurple -mb-2"
                icon-name="expense-union"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">Total Repayable</span>
              <span class="text-lg font-extrabold text-darkPurple mt-5">{{convertToCurrency(repaymentAmount)}}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card
          class="w-1/3 py-6 px-4"
        >
          <div class="flex justify-between">
            <card
              class="p-2"
              style="height: 40px;"
            >
              <Icon
                class-name="text-darkPurple -mb-2"
                icon-name="expense-union"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">Total Repaid</span>
              <span class="text-lg font-extrabold text-darkPurple mt-5">{{convertToCurrency(totalRepaid)}}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
        <Card
          class="w-1/3 py-6 px-4"
        >
          <div class="flex justify-between">
            <card
              class="p-2"
              style="height: 40px;"
            >
              <Icon
                class-name="text-darkPurple -mb-2"
                icon-name="expense-union"
                size="xsm"
              />
            </card>
            <div class="flex flex-col">
              <span class="text-xs font-semibold uppercase text-jet">Total Outstanding</span>
              <span class="text-lg font-extrabold text-darkPurple mt-5">{{convertToCurrency(totalOutstanding)}}</span>
            </div>
            <div class="border bg-white w-0" />
          </div>
        </Card>
      </div>
      <template v-slot:footer>
      </template>
    </card>
    <div class="flex" />
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Card from '@/components/Card';
import Icon from '@/components/Icon';

export default {
  name: "ConsolidatedAdvance",
  components: {
    Card,
    Icon,
    Loader,
  },
  data() {
    return {
      loading: true,
      interestAmount: 0,
      principalAmount: 0,
      repaymentAmount: 0,
      totalOutstanding: 0,
      totalRepaid: 0,
      amountData: 0,
    };
  },
  methods: {
    getAdvance() {
      this.$_getEmployeeConsolidatedAdvanceAndLoan({ id: this.$AuthUser.id }).then((result) => {
        this.interestAmount = result.data.interestAmount;
        this.principalAmount = result.data.principalAmount;
        this.repaymentAmount = result.data.repaymentAmount;
        this.totalOutstanding = result.data.totalOutstanding;
        this.totalRepaid = result.data.totalRepaid;

        this.amountData = this.interestAmount + this.principalAmount + this.repaymentAmount + this.totalOutstanding + this.totalRepaid;
        this.loading = false;
      });
    }
  },
  mounted() {
    this.getAdvance();
  }
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
